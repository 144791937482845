﻿import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ResultadoConsulta } from '../entities/resultadoconsulta.entity';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity'
import { BaseService} from '../services/base.service';
import { ValidacaoRegistroLista } from '../entities/validacaoregistrolista.entity';
import { Paging } from '../entities/paging.entity';
import { Usuario } from '../entities/usuario.entity';
import { UsuarioPerfil } from '../entities/usuarioperfil.entity';


@Injectable()
export class UsuarioService extends BaseService  {
    
    
	constructor(private http: HttpClient,  readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Usuario';             
    }
    public Listar(Nome : string,IdentificadorEmpresa : number,Login : string,Paginacao: Paging): Observable<ResultadoConsulta<Usuario>> {
     let params = new HttpParams();
        var url: string = this.apiUrl;
           if (Nome)
               params = params.set("Nome",Nome);
           if (IdentificadorEmpresa)
               params = params.set("IdentificadorEmpresa",IdentificadorEmpresa.toString());
           if (Login)
               params = params.set("Login",Login);
         if (Paginacao)
             params = this.MontarArrayPaginacaoOrdenacao(params, Paginacao);
            return this.http.get<ResultadoConsulta<Usuario>>(url, { params: params}).pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
            )
        }


    public Retornar(Identificador : number): Observable<Usuario>
    {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.get<Usuario>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        )
    }
 
    public Salvar(item: Usuario): Observable<ResultadoOperacao<Usuario>>
    {
        var url: string = this.apiUrl;
        return this.http.post<ResultadoOperacao<Usuario>>(url, item).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );

    }
 
    public Excluir(Identificador : number): Observable<ResultadoOperacao<Usuario>>
    {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.delete<ResultadoOperacao<Usuario>>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );
    }
 
    public AdicionarPerfis(item: ValidacaoRegistroLista<UsuarioPerfil>): Observable<ResultadoOperacao<UsuarioPerfil[]>> {
               const url = this.apiUrl + '/AdicionarPerfis';
                return this.http.post<ResultadoOperacao<UsuarioPerfil[]>>(url, item).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );

     }

    public RemoverPerfis(item: ValidacaoRegistroLista<UsuarioPerfil>): Observable<ResultadoOperacao<UsuarioPerfil[]>> {
               const url = this.apiUrl + '/RemoverPerfis';
                return this.http.post<ResultadoOperacao<UsuarioPerfil[]>>(url, item).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );
    }


        


}