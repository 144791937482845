﻿import { Component, OnInit, ViewEncapsulation, EventEmitter, Input, Output } from '@angular/core';

@Component({
    moduleId: module.id,
    template: `<div><ul class="" style="list-style: none;">
 <ng-template ngFor let-itemTree [ngForOf]="Itens">
    <treeview-node [ItemNo]="itemTree" [CampoNome]="CampoNome" [CampoSelecionado]="CampoSelecionado" [CampoFilhos]="CampoFilhos" Visivel="true" (ItemModificado)="TrocarSelecaoItem($event)" [Editavel]="Editavel"></treeview-node>
  </ng-template>
</ul>
</div>`,
    selector: 'treeview',
    encapsulation: ViewEncapsulation.None,

})


export class TreeViewComponent implements OnInit {
    @Input() Itens: Array<any>;
    @Input() CampoNome: string;
    @Input() CampoSelecionado: string;
    @Input() CampoFilhos: string;
    @Input() Editavel: boolean = true;
    @Output() ItemModificado: EventEmitter<any> = new EventEmitter<any>();
    
    constructor() {
    }

    public ngOnInit(): void {
    };     
    
    public TrocarSelecaoItem(item: any):void
    {
        this.ItemModificado.emit(item);
    }
}
