﻿import { Component, OnInit, ViewEncapsulation, EventEmitter, Input, Output, Host, Injector, ViewContainerRef } from '@angular/core';

@Component({
    moduleId: module.id,
    template: `
<li [hidden]="!Visivel">
     <div style='display:inline-block;'><input type="checkbox" [ngModel]="VerificarSelecionado()"  [ngModelOptions]="{standalone: true}" (click)="AjustarSelecionado()" [disabled]="!Editavel"  /></div>

<a (click)="TrocarVisivelFilho()"><i class="fa" [ngClass]="VerificarSeta()"></i>{{RetornarNome()}}</a>
<ul  class="" *ngIf="PossuiFilhos()" style="list-style: none;">
 <ng-template ngFor let-itemTree [ngForOf]="RetornarFilhos()">
        <treeview-node [ItemNo]="itemTree" [CampoNome]="CampoNome" [CampoSelecionado]="CampoSelecionado" [CampoFilhos]="CampoFilhos" [Visivel]="ExibeFilhos" [ItemPai]="ItemNo" (ItemModificado)="TrocarSelecaoItem($event)" [Editavel]="Editavel"></treeview-node>
  </ng-template>
</ul>
</li>`,
    selector: 'treeview-node',
    encapsulation: ViewEncapsulation.None,

})


export class TreeViewNodeComponent implements OnInit {
    @Input() ItemNo: any;
    @Input() CampoNome: string;
    @Input() CampoSelecionado: string;
    @Input() CampoFilhos: string;
    @Input() Visivel: boolean;
    @Input() ItemPai: any;
    @Input() Editavel: boolean;
    @Output() ItemModificado: EventEmitter<any> = new EventEmitter<any>();

    public ExibeFilhos:boolean= true;
    public NoPai: TreeViewNodeComponent;
    constructor(private inj: Injector, private vcRef: ViewContainerRef) {
        if ((<any>this.vcRef.injector).view != null) {
            var parentComponent1 = (<any>this.vcRef.injector).view.component;
            var checaTipo = parentComponent1 instanceof TreeViewNodeComponent ;
            if (checaTipo)
            {
                this.NoPai = parentComponent1;
            }
        }
    }

    public ngOnInit(): void {
        
    };    

    public RetornarNome(): string
    {
        return this.ItemNo[this.CampoNome];
    }

    public PossuiFilhos(): boolean
    {
        return this.ItemNo[this.CampoFilhos] != null && this.ItemNo[this.CampoFilhos].length > 0;
    }

    public VerificarSeta(): string {
        var classe: string = "";
        if (this.PossuiFilhos())
        {
            if (!this.ExibeFilhos)
                classe = "fa-chevron-right";
            else
                classe = "fa-chevron-down";
        }
        else
        {
            classe = "";
        }
        return classe;
    }

    public TrocarVisivelFilho(): void
    {
        this.ExibeFilhos = !this.ExibeFilhos;
    }

    public RetornarFilhos(): Array<any>
    {
        var Itens: Array<any>;
        if (this.PossuiFilhos())
        {
            Itens = <Array<any>>this.ItemNo[this.CampoFilhos];
        }
        return Itens; 
    }

    public VerificarSelecionado(): boolean
    {
        return this.ItemNo[this.CampoSelecionado];
    }

    public AjustarSelecionado():  void
    {
        var Selecionado: boolean = !this.ItemNo[this.CampoSelecionado];
        this.ItemNo[this.CampoSelecionado] = Selecionado;
        this.ItemModificado.emit(this.ItemNo);
        if (!Selecionado && this.PossuiFilhos())
        {
            this.DesmarcarItens(this.ItemNo);
        }
        else if (Selecionado && this.NoPai != null)
        {
            this.NoPai.MarcarPai();            
        }
        
    }

    public MarcarPai(): void
    {
        if (this.ItemNo ) {
            this.ItemNo[this.CampoSelecionado] = true;
            this.ItemModificado.emit(this.ItemNo);
            if (this.NoPai != null)
                this.NoPai.MarcarPai();            
        }
    }

    public TrocarSelecaoItem(item: any):void
    {
        this.ItemModificado.emit(item);
    }

    private DesmarcarItens(item: any): void
    {
        if (item[this.CampoFilhos] != null && item[this.CampoFilhos].length > 0)
        {
            var Itens: Array<any>;
            Itens = <Array<any>>item[this.CampoFilhos];
            Itens.forEach(d => {
                d[this.CampoSelecionado] = false;
                this.ItemModificado.emit(d);
                this.DesmarcarItens(d);
            });
        }
    }
}
