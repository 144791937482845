﻿import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ResultadoConsulta } from '../entities/resultadoconsulta.entity';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity'
import { BaseService} from '../services/base.service';
import { ValidacaoRegistroLista } from '../entities/validacaoregistrolista.entity';
import { Paging } from '../entities/paging.entity';
import { Empreendimento } from '../entities/empreendimento.entity';


@Injectable()
export class EmpreendimentoService extends BaseService  {
    
    
	constructor(private http: HttpClient,  readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Empreendimento';             
    }
    public Listar(Codigo : number,Nome : string,IdentificadorEmpresa : number,Paginacao: Paging): Observable<ResultadoConsulta<Empreendimento>> {
     let params = new HttpParams();
        var url: string = this.apiUrl;
           if (Codigo)
               params = params.set("Codigo",Codigo.toString());
           if (Nome)
               params = params.set("Nome",Nome);
           if (IdentificadorEmpresa)
               params = params.set("IdentificadorEmpresa",IdentificadorEmpresa.toString());
         if (Paginacao)
             params = this.MontarArrayPaginacaoOrdenacao(params, Paginacao);
            return this.http.get<ResultadoConsulta<Empreendimento>>(url, { params: params}).pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
            )
        }


    public Retornar(Identificador : number): Observable<Empreendimento>
    {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.get<Empreendimento>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        )
    }
 
    public Salvar(item: Empreendimento): Observable<ResultadoOperacao<Empreendimento>>
    {
        var url: string = this.apiUrl;
        return this.http.post<ResultadoOperacao<Empreendimento>>(url, item).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );

    }
 
    public Excluir(Identificador : number): Observable<ResultadoOperacao<Empreendimento>>
    {
        var url: string = this.apiUrl + '/Identificador.toString()';
        return this.http.delete<ResultadoOperacao<Empreendimento>>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );
    }
 

        


}