﻿import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ResultadoConsulta } from '../entities/resultadoconsulta.entity';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity'
import { BaseService} from '../services/base.service';
import { ValidacaoRegistroLista } from '../entities/validacaoregistrolista.entity';
import { Paging } from '../entities/paging.entity';
import { Apresentante } from '../entities/apresentante.entity';


@Injectable()
export class ApresentanteService extends BaseService  {
    
    
	constructor(private http: HttpClient,  readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Apresentante';             
    }
    public Listar(IdentificadorEmpresa : number,CNPJ: string, Nome: string, Paginacao: Paging): Observable<ResultadoConsulta<Apresentante>> {
     let params = new HttpParams();
        var url: string = this.apiUrl;
           if (IdentificadorEmpresa)
               params = params.set("IdentificadorEmpresa",IdentificadorEmpresa.toString());
               if (CNPJ)
               params = params.set("CNPJ",CNPJ.toString());

               if (Nome)
               params = params.set("Nome",Nome.toString());

         if (Paginacao)
             params = this.MontarArrayPaginacaoOrdenacao(params, Paginacao);
            return this.http.get<ResultadoConsulta<Apresentante>>(url, { params: params}).pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
            )
        }


    public Retornar(Identificador : number): Observable<Apresentante>
    {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.get<Apresentante>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        )
    }
 
    public Salvar(item: Apresentante): Observable<ResultadoOperacao<Apresentante>>
    {
        var url: string = this.apiUrl;
        return this.http.post<ResultadoOperacao<Apresentante>>(url, item).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );

    }
 
    public Excluir(Identificador : number): Observable<ResultadoOperacao<Apresentante>>
    {
        var url: string = this.apiUrl + '/Identificador.toString()';
        return this.http.delete<ResultadoOperacao<Apresentante>>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );
    }
 

        


}