import { FormsModule } from '@angular/forms';

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TreeViewComponent } from './treeview.component';
import { TreeViewNodeComponent } from './treeviewnode.component';
import { TranslateModule } from '../../translate/translate.module';

@NgModule({
  declarations: [TreeViewComponent, TreeViewNodeComponent],
  imports: [CommonModule, FormsModule, TranslateModule.forRoot()],
  providers: [],
  exports: [TreeViewComponent, TreeViewNodeComponent]
})
export class TreeViewModule {}
