﻿import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ResultadoConsulta } from '../entities/resultadoconsulta.entity';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity'
import { BaseService} from '../services/base.service';
import { ValidacaoRegistroLista } from '../entities/validacaoregistrolista.entity';
import { Paging } from '../entities/paging.entity';
import { OrigemRecursos } from '../entities/origemrecursos.entity';


@Injectable()
export class OrigemRecursosService extends BaseService  {
    
    
	constructor(private http: HttpClient,  readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'OrigemRecursos';             
    }
    public Listar(Codigo : number,Descricao : string,Ativo : boolean,Paginacao: Paging): Observable<ResultadoConsulta<OrigemRecursos>> {
     let params = new HttpParams();
        var url: string = this.apiUrl;
           if (Codigo)
               params = params.set("Codigo",Codigo.toString());
           if (Descricao)
               params = params.set("Descricao",Descricao);
               if (Ativo != null && Ativo != undefined)

               params = params.set("Ativo",Ativo.toString());
         if (Paginacao)
             params = this.MontarArrayPaginacaoOrdenacao(params, Paginacao);
            return this.http.get<ResultadoConsulta<OrigemRecursos>>(url, { params: params}).pipe(
                map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
            )
        }


    public Retornar(Identificador : number): Observable<OrigemRecursos>
    {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.get<OrigemRecursos>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        )
    }
 
    public Salvar(item: OrigemRecursos): Observable<ResultadoOperacao<OrigemRecursos>>
    {
        var url: string = this.apiUrl;
        return this.http.post<ResultadoOperacao<OrigemRecursos>>(url, item).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );

    }
 
    public Excluir(Identificador : number): Observable<ResultadoOperacao<OrigemRecursos>>
    {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.delete<ResultadoOperacao<OrigemRecursos>>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );
    }
 

        


}