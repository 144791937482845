﻿import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ResultadoConsulta } from '../entities/resultadoconsulta.entity';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity'
import { BaseService } from '../services/base.service';
import { ValidacaoRegistroLista } from '../entities/validacaoregistrolista.entity';
import { Paging } from '../entities/paging.entity';
import { Empresa } from '../entities/empresa.entity';


@Injectable()
export class EmpresaService extends BaseService {


    constructor(private http: HttpClient, readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Empresa';
    }
    public Listar(Nome: string, CNPJ: string, Ativo: boolean, Paginacao: Paging): Observable<ResultadoConsulta<Empresa>> {
        let params = new HttpParams();
        var url: string = this.apiUrl;
        if (CNPJ)
            params = params.set("Codigo", CNPJ.toString());
        if (Nome)
            params = params.set("Descricao", Nome);
        if (Ativo != null && Ativo != undefined)
            params = params.set("Ativo", Ativo.toString());

        if (Paginacao)
            params = this.MontarArrayPaginacaoOrdenacao(params, Paginacao);
        return this.http.get<ResultadoConsulta<Empresa>>(url, { params: params }).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        )
    }


    public Retornar(Identificador: number): Observable<Empresa> {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.get<Empresa>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        )
    }

    public Salvar(item: Empresa): Observable<ResultadoOperacao<Empresa>> {
        var url: string = this.apiUrl;
        return this.http.post<ResultadoOperacao<Empresa>>(url, item).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );

    }

    public Excluir(Identificador: number): Observable<ResultadoOperacao<Empresa>> {
        var url: string = this.apiUrl + '/' + Identificador.toString();
        return this.http.delete<ResultadoOperacao<Empresa>>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        );
    }





}